<template>
  <v-container id="login-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            full-header
            color="primary"
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">Login</div>
              </div>
            </template>

            <v-card-text class="text-center">
              <v-text-field
                v-model="form.email"
                color="secondary"
                placeholder="Email..."
                prepend-icon="mdi-email"
                :error-messages="emailErrors"
                maxlength="35"
                label="Email"
                required
                @input="$v.form.email.$touch()"
                @blur="$v.form.email.$touch()"
              />

              <v-text-field
                type="password"
                class="mb-8"
                color="secondary"
                placeholder="Password..."
                prepend-icon="mdi-lock-outline"
                v-model="form.password"
                :error-messages="passwordErrors"
                maxlength="30"
                label="Password/Contraseña"
                v-on:keyup.enter="onLogin"
                required
                @input="$v.form.password.$touch()"
                @blur="$v.form.password.$touch()"
              />
              <v-alert :value="error" color="error" dense outlined>
                {{ errorMsg }}
              </v-alert>
              <v-btn
                rounded
                color="primary"
                text
                large
                :disabled="$v.$invalid"
                v-on:click.prevent="onLogin"
                :loading="isLoading"
              >
                Login
              </v-btn>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

import MaterialCard from "@/components/base/MaterialCard";

export default {
  name: "login",
  components: {
    MaterialCard,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: false,
      errorMsg: "",
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions(["login", "setLoading"]),
    async onLogin() {
      try {
        this.error = false;
        this.errorMsg = "";
        this.setLoading(true);
        const response = await this.login(this.form);
        this.setLoading(false);
        if (response) {
          this.$router.push({
            path: "/home",
          });
        } else {
          this.error = true;
          this.errorMsg = "Invalid user/password";
        }
      } catch (e) {
        this.setLoading(false);
        this.error = true;
        this.errorMsg = "Error";
      }
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email &&
        errors.push("Invalid email/Email es inválido");
      !this.$v.form.email.required && errors.push("Required/Requerido");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Required/Requerido");
      return errors;
    },
  },
};
</script>

<style>
.v-input .v-label {
  top: 0 !important;
}
</style>
